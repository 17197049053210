.testimonials-container {
    margin-top: 60px;
    margin-bottom: 60px;
    background-color: #F9F7F3;
    position: relative;
}
.testimonials-container::before{
    content: "";
    position: absolute;
    top: 0;
    width: 90%;
    height: 100%;
    background-image: url("../assets/images/testimonial-before.webp");
    background-repeat: no-repeat;
}
.testimonials-container .owl-carousel {
    padding: 0;
}
.testimonial-item {
    display: flex;
    justify-content: center;

}
.testimonial-slide-col{
    padding-top: 100px;
    padding-bottom: 40px;
}
.testimonial-content {
    padding-right: 20px;
    padding-left: 0;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
}
.testimonial-name {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: #161616;
    font-family: 'Lato', serif; 
}
.testimonial-rating {
    color: #ffc107;
    display: flex;
    margin-bottom: 20px;
}

.testimonial-text {
    color: #161616;
    font-weight: 700;
}
.owl-nav {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    z-index: 10;
}
.owl-nav button {
    background: none;
    border: none;
    cursor: pointer;
}

.owl-nav button img {
    width: 30px;
    height: 30px;
}

.owl-nav .owl-prev {
    position: absolute;
    left: -40px;
}

.owl-nav .owl-next {
    position: absolute;
    right: -40px;
}

.custom-owl-dots {
    display: flex;
    align-items: center;
    gap: 30px;
}
.testimonials-container .owl-carousel .owl-dots {
    display: none;
}

.test-para{
    transform: translateY(15.5748%);
    right: 305px;
    top: 5px;
}
.test-para img{
    width: 100px;
}
@media (min-width:601px) and (max-width:768px){
    .testimonial-text{
        min-height: 205px;
    }
}
@media (min-width:769px) and (max-width:992px){
    .testimonial-text{
        min-height: 150px;
    }
}