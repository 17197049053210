	/*
  	Flaticon icon font: Flaticon
  	Creation date: 15/03/2016 20:45
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("Flaticon.eot");
  src: url("Flaticond41d.eot?#iefix") format("embedded-opentype"),
       url("Flaticon.woff") format("woff"),
       url("Flaticon.ttf") format("truetype"),
       url("Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;

}

.flaticon-alphabet:before { content: "\f100"; }
.flaticon-animals:before { content: "\f101"; }
.flaticon-arrow:before { content: "\f102"; }
.flaticon-arrows:before { content: "\f103"; }
.flaticon-arrows-1:before { content: "\f104"; }
.flaticon-back:before { content: "\f105"; }
.flaticon-book:before { content: "\f106"; }
.flaticon-book-1:before { content: "\f107"; }
.flaticon-clip:before { content: "\f108"; }
.flaticon-clock:before { content: "\f109"; }
.flaticon-folder:before { content: "\f10a"; }
.flaticon-fruit:before { content: "\f10b"; }
.flaticon-game:before { content: "\f10c"; }
.flaticon-gestures:before { content: "\f10d"; }
.flaticon-hat:before { content: "\f10e"; }
.flaticon-interface:before { content: "\f10f"; }
.flaticon-left:before { content: "\f110"; }
.flaticon-music:before { content: "\f111"; }
.flaticon-people:before { content: "\f112"; }
.flaticon-people-1:before { content: "\f113"; }
.flaticon-people-2:before { content: "\f114"; }
.flaticon-people-3:before { content: "\f115"; }
.flaticon-people-4:before { content: "\f116"; }
.flaticon-people-5:before { content: "\f117"; }
.flaticon-people-6:before { content: "\f118"; }
.flaticon-people-7:before { content: "\f119"; }
.flaticon-people-8:before { content: "\f11a"; }
.flaticon-people-9:before { content: "\f11b"; }
.flaticon-person:before { content: "\f11c"; }
.flaticon-person-1:before { content: "\f11d"; }
.flaticon-school:before { content: "\f11e"; }
.flaticon-school-1:before { content: "\f11f"; }
.flaticon-school-2:before { content: "\f120"; }
.flaticon-school-3:before { content: "\f121"; }
.flaticon-school-4:before { content: "\f122"; }
.flaticon-smile:before { content: "\f123"; }
.flaticon-symbol:before { content: "\f124"; }
.flaticon-symbols:before { content: "\f125"; }
.flaticon-tool:before { content: "\f126"; }
.flaticon-tool-1:before { content: "\f127"; }
.flaticon-transport:before { content: "\f128"; }
.flaticon-two:before { content: "\f129"; }
.flaticon-two-1:before { content: "\f12a"; }