.notfound-section {
    margin: 0 230px;
    margin-top: 60px;
}

.notfound-container {
    display: flex;
    padding: 60px 0;
    justify-content: space-around;
}

.notfound-container h1 {
    font-size: 180px;
    font-weight: bold;
    margin-right: 180px;
    color: gray;
    margin-top: 30px;
}

.notfound-container h5 {
    margin-top: 0;
}

.notfound-page-links li {
    color: black;
    cursor: pointer;
}










@media screen and (max-width:600px) {
    .notfound-section {
        margin: 0 10px;
        margin-top: 60px;
    }

    .notfound-container {
        justify-content: space-between;
    }

    .notfound-container h1 {

        margin-right: 0px;

    }
}


@media (min-width:600px) and (max-width:884px) {
    .notfound-section {
        margin: 0 50px;
        margin-top: 60px;
    }
}

@media (min-width:885px) and (max-width:1080px) {
    .notfound-section {
        margin: 0 100px;
        margin-top: 60px;
    }
}