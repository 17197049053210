body {
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-weight: 400;
  color: #666;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  font-family: 'Lato', serif;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

p,
ul,
ol,
button {
  color: #304566;
  font-size: 16px;
  line-height: 30px;
  font-family: 'Lato', serif;
}

/* Headings */

.font-salsa {
  font-family: "Salsa", serif;
}

h1 {
  font-size: 60px;
  margin: 0;
  font-weight: 400;
  line-height: 70px;
  color: #333;
  margin-bottom: 10px;
  /* font-family: 'Baloo', serif; */
  font-family: "Salsa", serif;
}

h2 {
  /* text-transform: uppercase; */
  font-size: 42px;
  /* font-family: 'Baloo', sans-serif; */
  font-family: "Salsa", serif;
  margin-top: 0px;
  margin-bottom: 20px;
  color: #304566;
}

h3 {
  /* font-family: 'Baloo', sans-serif; */
  font-family: "Salsa", serif;
  font-size: 40px;
  margin-top: 0px;
  color: #304566;
  margin-bottom: 20px;
}

h4 {
  color: #304566;
  font-size: 32px;
  margin-bottom: 20px;
  /* font-family: 'Baloo', sans-serif; */
  font-family: "Salsa", serif;
}

h5 {
  font-size: 24px;
  margin: 0;
  color: #304566;
  font-weight: 700;
  letter-spacing: 0.4px;
  /* font-family: 'Lato', serif; */
  font-family: "Salsa", serif;
  margin-bottom: 20px;
  margin-top: 20px;
}

h6 {
  color: #ffffff;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  /* text-transform: uppercase; */
  letter-spacing: 0.4px;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  letter-spacing: 01px;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

/* Links */

a {
  transition: color .2s ease-in-out;
}

a:hover,
a:focus {
  text-decoration: none;
}

/* Others */

.text-light,
.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6,
.text-light p {
  color: #fff !important;
}

i {
  transition: all .2s ease-in-out;
}

img {
  transition: all .2s ease-in-out;
}

.small-heading {
  font-size: 1.100em;
  margin-bottom: 10px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
  padding-bottom: 15px;
}



/* Newsletter */

.pdf-header {
  width: 610px;
  display: flex;
  justify-content: flex-end;
}

.pdf-header i {
  color: #fff;
  font-size: 24px;
  margin: 5px;
}

@media screen and (max-width:767px) {
  .pdf-header {
    width: 100%;
  }

  .pdf-container {
    width: 100%;
  }

  .pdf-container canvas {
    width: 100%;
    height: auto;

  }
}


.p-view {
  height: 750px;
}

@media screen and (max-width:576px) {
  .p-view {
    height: 100%;
  }
}


/*===========================
Preloader
============================ */

#preloader {
  background: #f5f5f5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.spinner {
  position: relative;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 46%;
  animation: rotate 2.0s infinite linear;
}

.spinner>div {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: inline-block;
  animation: bouncedelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */

  animation-fill-mode: both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes bouncedelay {

  0%,
  80%,
  100% {
    transform: scale(0.0);
  }

  40% {
    transform: scale(1.0);
  }
}

/*===========================
Navigation
============================ */
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
}

.navbar {
  z-index: 999;
  margin-bottom: 0 !important;
  width: 100%;
  border: 0px;
  top: 0px;
  transition: all 0.7s ease-in-out;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.10);
  min-height: 90px !important;
}

.navbar-nav>li>a {
  padding: 10px 25px;
}

.navbar-custom ul.nav li a {
  font-size: 15px;
  text-align: center;
  transition: all .2s ease-in-out;
  font-family: 'Lato', sans-serif;
  background: transparent;
  letter-spacing: 0.4px;
  display: block;
  line-height: 50px;
  font-weight: 700;
  color: #282A73;
  position: relative;
}

.navbar-right li {
  margin-right: 0px;
  height: 33px;
  line-height: 50px;
  position: relative;
}

/* .navbar-custom .nav a:hover,
.navbar-custom .nav>.active>a {
  padding-top: 20px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.10);
  padding-bottom: 20px;
  color: #fff;
  border-radius: 0px 0px 5px 5px;
} */

.navbar-custom .nav li::before {
  content: "";
  position: absolute;
  bottom: -52px;
  left: 35%;
  background-image: url("../images/nav-dot.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0;
  /* Start hidden */
  transition: opacity 0.3s ease;
  /* Add transition */
}

.navbar-custom .nav li:hover::before,
.navbar-custom .nav li.active::before {
  opacity: 1;
  /* Make it visible */
}

.navbar-custom .nav li:hover .dropdown-menu li::before,
.navbar-custom .nav li.active .dropdown-menu li::before {
  display: none !important;
}

.navbar-custom ul.nav ul.dropdown-menu li a:hover {
  padding-top: 0px;
  padding-bottom: 0px;
}

.navbar-custom ul.nav ul.dropdown-menu {
  text-align: center;
  border: 0px;
  border-radius: 5px;
  padding: 5px;
  /* max-height: 205px;
  overflow-y: auto; */
}

.navbar-custom ul.nav ul.dropdown-menu li:last-child {
  border-bottom: none;
}

.navbar-custom ul.nav ul.dropdown-menu li a {
  margin: 5px;
  line-height: 50px;
  display: inline-block;
  border-radius: 5px;
}

.navbar-custom ul.nav ul.dropdown-menu li a:hover {
  padding-top: 0px;
  line-height: 50px;
  padding-bottom: 0px;
  background-color: #EE4323;
  color: #fff;
}

/* Responsive navbar  */

.navbar-toggle:focus,
.navbar-toggle:active {
  outline: 0;
}

.navbar-toggle {
  font-size: 30px;
  color: #fff;
  transition: all .2s ease-in-out;
  position: relative;
  float: right;
  border-radius: 50%;
  min-width: 70px;
}

/*===========================
Logo
============================ */

.navbar-brand {
  line-height: 20px;
  margin: auto;
  position: absolute;
  transition: all .2s ease-in-out;
}

.navbar-brand img {
  max-width: 100px;
  position: absolute;
  left: 0;
  right: 0;
}

/* =========================== Index page ============================ */
/* ===========================
Main Slider
============================ */


#layerslider {
  padding-top: 60px;
  width: 100% !important;
  height: 750px !important;
}

.ls-bottom-nav-wrapper {
  margin-top: -40px !important;
  z-index: 99 !important;
}

.ls-fullwidth .ls-nav-prev,
.ls-fullwidth .ls-nav-next {
  z-index: 100 !important;
}

/* Slider text boxes  */

.header-text {
  padding: 30px !important;
  max-width: 450px;
  left: 13% !important;
  right: 25% !important;
  font-family: "Salsa", serif;
  top: 22% !important;
  text-shadow: 0px 5px 5px #0000009C;
  z-index: 1;
}

.header-text h1 {
  margin-top: 0px;
  color: #fff;
}

.header-text p {
  color: #fff;
}

.main-slide:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  left: 0;
  top: 0;
  background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.35), transparent 68%);
}


/* ===========================
--- Services
============================ */

.serviceBox {
  text-align: center;
  padding: 40px;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.serviceBox img {
  transition: all 0.3s ease-in-out;
}

.serviceBox:hover.serviceBox img {
  transform: rotate(-10deg);
}

.serviceBox .service-content {
  position: relative;
  transition: all 0.3s ease-in-out 0s;
}

.serviceBox .title {
  margin-bottom: 10px;
  padding: 20px;
}

.serviceBox .btn-outline {
  max-width: unset;
  width: 170px;
  margin: auto auto 0;
  border: none;
}

/* ===========================
--- Call to Action
============================ */

#call-to-action1 {
  background: url(../images/Nurture-image-resize--2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

#call-to-action2 {
  background: url(../img/bg_calltoaction2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

/* ===========================
--- Testimonials
============================ */

#testimonials {
  background: linear-gradient(to bottom, #66CCFF 18%, rgba(255, 255, 255, 0) 120%);
}

.testimonial {
  padding: 20px 15px 50px;
  margin-bottom: 20px;
}

.testimonial:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  right: auto;
  bottom: auto;
  border: 20px solid;
  border-color: #ffffff #ffffff transparent transparent;
  margin-top: -42px;
}

.testimonial .description {
  background: #fff;
  color: #333333;
  border-radius: 40px;
  font-size: 14px;
  box-shadow: 10px 0px 0px 0px rgba(153, 153, 153, 0.22);
  padding: 45px 35px;
  position: relative;
}

.testimonial .description:before {
  content: "\f10d";
  font-family: 'FontAwesome';
  position: absolute;
  top: -27px;
  font-size: 40px;
}

.testimonial-review {
  padding-top: 32px;
}

.testimonial-review img {
  float: left;
  max-width: 100px;
  margin-left: 25px;
  margin-right: 20px;
  border: 5px solid;
}

.testimonial-review .testimonial-title {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
  float: left;
}

.testimonial-title small {
  display: block;
  color: #999;
  font-size: 12px;
  margin-top: 8px;
  font-weight: 700;
}

/* ===========================
--- About
============================ */
/* Feature icons */

#about .media i {
  font-size: 90px;
  padding: 10px;
  transition: all 0.3s ease-in-out 0s;
  vertical-align: middle;
  line-height: 90px;
  display: inline-block;
}

#about .media-heading {
  display: inline-block;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  color: #fff;
  transform: rotate(5deg);
}

.features .media {
  margin-top: 0px;
}

.features {
  margin-top: 70px;
}


.team-member img {
  max-width: 200px;
  border: 8px solid;
  height: auto;
  margin: 0 auto;
}

.team-member .social {
  transition: all 0.3s ease-out 0s;
  top: 250px;
  position: absolute;
  display: block;
  width: 20%;
}

/* .team-member:hover .social {
  transition: opacity(1);
} */

.team-member .social-icon {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 30px;
  position: absolute;
  bottom: 95px;
  right: 15px;
}

.team-member .social-icon li a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  font-size: 13px;
  color: #fff;
  text-align: center;
  margin: 4px 0;
  transform: scale(1);
  transition: all 0.2s;
  background-color: #34367a !important;
}

/* .team-member:hover .social-icon li a {
  transform: scale(1);
} */

.team-member .team-info {
  padding: 15px 15px 20px;
  color: #fff;
  border-radius: 40px 10px;
  text-align: center;
  right: 0;
  transition: all 0.2s ease-out 0s;
  margin-top: -20px;
}

.team-member .team-info h4 {
  color: #fff;
  margin-bottom: 5px;
}

.team-member .post {
  display: block;
  font-size: 14px;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 900;
  text-transform: uppercase;
}

.team-member .description {
  text-align: left;
  border-radius: 20px;
  padding: 20px;
  background: #fff;
  margin-top: 20px;
}

/* ===========================
--- Tuition
============================ */

#tuition {
  background-image: url(../img/bggreen.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.pricingTable {
  padding: 30px 0;
  margin: 30px 0;
  text-align: center;
  position: relative;
  transition: all 0.3s ease-out 0s;
}

.pricingTable:before,
.pricingTable:after {
  content: "";
  width: 72%;
  height: 52px;
  margin: 0 auto;
  position: absolute;
  top: -32px;
  left: 0;
  right: 0;
  transform: rotate(-3deg) skew(-3deg);
  transition: all 0.3s ease-out 0s;
}

.pricingTable:after {
  top: auto;
  bottom: -32px
}

.pricingTable .price-value {
  padding: 8px 0;
  font-size: 45px;
  font-weight: bold;
  line-height: 45px;
}

.pricingTable:hover .price-value,
.pricingTable:hover .month {
  color: #fff;
}

.pricingTable .month {
  font-size: 15px;
  margin-top: 10px;
  line-height: 24px;
  font-weight: 900;
  display: block;
}

.pricingTable .title {
  font-size: 30px;
  display: block;
  padding-bottom: 20px;
  letter-spacing: -1px;
  font-family: Baloo;
}

.pricingTable:hover .title {
  color: #fff;
}

.pricingTable .pricing-content {
  padding: 0 5%;
  margin: 20px 0 20px 0;
  list-style: none;
}

.pricingTable .pricing-content li {
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid #e6e5e5;
  font-size: 15px;
}

.pricingTable .pricing-content li:last-child {
  border-bottom: 0;
}

.pricingTable:hover .pricing-content li {
  color: #fff;
}

/* ===========================
--- Latest Blog Posts
============================ */

.blog-prev {
  transition: all 0.5s ease-in-out 0s;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  text-align: left;
}

.blog-prev img {
  border-radius: 20px 20px 0px 0px;
  max-width: 100%;
  vertical-align: top;
  position: relative;
  transition: all 0.5s ease-in-out 0s;
}

.blog-prev .blog-caption {
  padding: 25px 20px 25px;
  transition: all 0.5s ease-in-out 0s;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.blog-prev .date {
  color: #fff;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 900;
  min-height: 70px;
  transition: all 0.5s ease-in-out 0s;
  min-width: 70px;
  padding: 10px 0;
  position: absolute;
  right: 15px;
  text-align: center;
  text-transform: uppercase;
  top: 0;
}

.blog-prev .date span {
  display: block;
  line-height: 24px;
}

.blog-prev .date .month {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
}

.blog-prev h4,
.blog-prev p {
  margin: 5px;
  color: #fff;
  padding: 0;
}

.blog-prev a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

.blog-prev:hover .hover {
  transition-delay: 0.1s;
  opacity: 0.6;
}

.blog-prev:hover .date {
  transform: translateY(100%);
}

/* ===========================
--- Gallery Polaroid
============================ */
/* Polaroids */

#lightbox {
  overflow: visible !important;
}

.polaroids p {
  font-size: 18px;
  text-align: center;
  padding-top: 40px;
  font-weight: 700;
  color: #696767;
  transform: translate3d(0, 0, 0);
}

.polaroid-item {
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, .25); */
  /* padding: 20px; */
  /* margin: 10px; */
  background-color: #fff;
  background: url(../img/polaroid_texture.png) repeat;
  transition: transform .8s ease-in-out;
}

.polaroid-item button {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
}

.polaroid-item img {
  width: 100%;
  margin-bottom: -5px;
  /* height: 320px; */
  object-fit: cover;
}

.gallery-container {
  max-width: 1920px;
  margin: 0 auto;
}

.gallery-container .col-lg-3 {
  padding: 0 !important;
}

/* Hover effect*/

.polaroid-item:hover {
  transform: rotate(9deg);
}

/* Isotope */

.isotope-item {
  z-index: 2
}

.isotope-hidden.isotope-item {
  z-index: 1
}

.isotope,
.isotope .isotope-item {
  transition-duration: 0.8s;
}

.isotope-item {
  margin-right: -1px;
  backface-visibility: hidden;
}

.isotope {
  transition-property: height, width;
}

.isotope .isotope-item {
  transition-property: transform, opacity;
}

/* Map */

#map-canvas {
  width: 100%;
  height: 320px;
}

.contact-info p+p {
  line-height: 20px;
}

/* =========================== Blog Pages ============================ */

#blog-container {
  padding: 0px;
}

/* date & category badge */

.date-category {
  position: absolute;
  z-index: 10;
  color: #fff;
  margin: 10px;
  padding: 20px;
  width: 150px;
  border-radius: 20px;
}

.date-category p {
  color: #fff;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  margin: 10px 0px 0px 0px;
}

.date-category i {
  color: #fff;
}

/* Latest posts Widget */

.blog-latest {
  padding: 10px;
  border-bottom: 1px dotted #cecece;
}

.blog-latest:last-child {
  border-bottom: 0;
}

.blog-latest p {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-family: 'Lato';
  line-height: 18px;
}

.blog-latest span {
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  text-transform: none;
  line-height: 24px;
  margin-bottom: 15px;
  display: inline-block;
}

.blog-latest .col-xs-8 {
  padding: 0px;
}

/* Post info */

.post-info p {
  margin-right: 15px;
  display: inline-block;
  font-weight: 700;
  font-size: 11px;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

.post-info p+p {
  line-height: 0px !important;
}

.post-info i {
  margin-right: 5px;
}

/* blog post */

.blog-post {
  border-bottom: 1px dashed;
  padding: 60px 0;
  margin: 0 30px;
}

.blog-post:first-child {
  padding-top: 0;
}

.blog-post:last-child {
  border-bottom: 0px;
}

.blog-post h3 {
  margin-bottom: 10px;
  margin-top: 20px;
  transition: all 0.2s ease-in-out;
}

.blog-post h3 a {
  color: #5b5a5a;
}

.blog-post .post-info i {
  margin-right: 5px;
}

.single-page .blog-post h3 {
  margin-top: 0px;
}

.post-main {
  padding: 0 0 40px 0;
}

.post-main img {
  margin-bottom: 20px;
}

/* Blog main- images */

.img-blog {
  border: 5px solid;
  overflow: hidden;
}

.img-blog:hover,
.img-blog img {
  transition: all 0.9s;
}

.img-blog:hover img {
  transform: scale3d(1.2, 1.2, 1);
}

/* Blog tags */

.blog-tags a {
  font-weight: 900;
  color: #fff;
  font-size: 12px;
  font-family: Lato;
  border-radius: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 10px 0 20px;
  position: relative;
  letter-spacing: 0.5px;
  margin: 0 10px 10px 0;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.blog-tags a:hover,
.blog-tags a:focus {
  color: #fff;
}

.blog-tags a::before {
  background: #fff;
  border-radius: 5px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.post-info .blog-tags {
  margin-top: 20px;
}

/* Comments */

.comment.media-body {
  margin-right: 10px;
}

.comments-block .btn {
  padding: 10px;
}

.comments-block {
  margin-top: 70px;
  display: inline-block;
}

.comments-block .media-object {
  max-width: 120px;
  float: left;
  margin-right: 20px;
  border: 5px solid;
  transition: all 0.5s ease-in-out;
}

.comment {
  border-bottom: 1px dotted;
  margin: 20px auto 20px;
  padding: 30px;
  position: relative;
}

.comment:last-child {
  border-bottom: 0;
}

.nested {
  display: inline-block;
}

/* =========================== Footer and Credits ============================ */
/* footer */

footer {
  position: relative;
  padding: 45px 0px;
  margin-top: 110px;
}

footer.no-margin {
  margin-top: 0px;
}

footer li {
  /* border-bottom: 1px solid #cecece; */
  line-height: 28px !important;
}

footer .footer-icons i {
  font-size: 13px;
  width: 12px;
  margin-right: 15px;
}

.credits {
  font-size: 14px;
  font-weight: 600;
}

footer h6 {
  font-size: 21px;
  margin-bottom: 18px;
}

/* Go to top icon */

.back-to-top {
  display: none;
  transition: all 0.3s ease-in-out;
  position: fixed;
  bottom: 58px;
  height: 40px;
  width: 40px;
  right: 22px;
  border-radius: 20px 40px;
  box-shadow: 2px 1px 20px rgba(0, 0, 0, 0.29);
  z-index: 9999;
}

.back-to-top i {
  font-size: 32px;
  color: #fff !important;
  line-height: 38px;
  text-align: center;
}

.back-to-top:hover i {
  transform: scale(1.2);
}

/* =========================== Miscellaneous  ============================ */
/* Owl-carousel */

.owl-carousel {
  padding: 0px 50px;
  transition: all .3s ease-in-out;
}

.owl-carousel .owl-item {
  perspective: 1000px;
}

.owl-prev,
.owl-next {
  border: 2px solid #fff;
  position: absolute;
  top: 50%;
  z-index: 99;
  transition: all .3s ease-in-out;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
}

.owl-prev i,
.owl-next i {
  color: #fff;
  font-size: 20px;
  transition: all .3s ease-in-out;
  line-height: 35px;
}

.owl-next {
  right: 15px;
}

.owl-prev {
  left: 15px;
}

.owl-carousel.owl-loaded {
  display: block;
  clear: both;
  /* Important fix */
}

.owl-dots {
  text-align: center;
  margin: 20px auto;
}

.owl-dot {
  transition: transform 0.3s;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 1px solid #ccc;
  background: #cecece;
  margin-right: 10px;
  display: inline-block;
}

.owl-dot:hover,
.owl-dot.active {
  transform: scale(1.5);
}

/* Sections */

section {
  padding: 80px 0px;
}



.small-section {
  position: relative;
  padding: 70px 0px !important;
}

/* main wrapper */

#main {
  background: #fff;
}

/* #main section {
  margin-top: 70px;
  margin-bottom: 70px;
} */

/* Sections heading */

.section-heading {
  margin-bottom: 50px;
  position: relative;
}

.heading::before {
  content: "";
  position: absolute;
  top: -25px;
  background-image: url("../images/section-before.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 95px;
  height: 6px;
}

.section-heading h2 {
  text-align: center;
  margin-bottom: 20px;
}

.section-heading h2 .heading:before {
  left: 46%;
}

.section-heading .hr {
  border-top: 1px dotted;
  text-align: center;
  max-width: 150px;
  margin-top: 50px;
  margin: 0 auto;
}

.section-heading .hr:after {
  content: "\f101";
  font-family: Flaticon;
  display: inline-block;
  position: relative;
  top: -25px;
  padding: 0 10px;
  font-size: 38px;
}

.section-heading .hr.light,
.section-heading .hr.light:after {
  color: #fff;
}

/* Custom Link */

.custom-link {
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  border-bottom: 2px solid #cecece;
  padding-bottom: 5px;
  margin-top: 20px;
}

/* Page blocks */

.page-block {
  margin-top: 70px;
}

/* no padding */

.no-padding {
  padding: 0px;
}

.no-padding-bottom {
  padding-bottom: 0px;
}

/* elements page */

#elements .row {
  margin-right: 0px;
  margin-left: 0px;
}

#elements h4.sub-header {
  padding: 10px;
  text-align: center;
  border-radius: 0px;
}

#elements h3.sub-header {
  padding: 10px;
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
}

#elements .bg-colorprev .col-md-3 {
  padding: 20px;
}

.glyph {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  padding: 20px;
  width: 19%;
}

.glyph div {
  padding: 10px;
  display: block;
  margin-top: 20px;
  font-size: 52px;
  color: #2F2F2F;
  margin-left: 0;
}

.page-header {
  border-bottom: 2px solid #eee !important;
  margin-bottom: 50px;
}

.page-header h2 {
  display: inline-table;
}

/* Img Styles */

.img-curved {
  /* border-radius: 20px 100px; */
  border-radius: 15px;
}

.img-opacity:hover {
  opacity: 0.7;
}

.img-rounded {
  border-radius: 40px;
}

/* Image Zoom Hover effect */

.img-effect {
  overflow: hidden;
}

.img-effect img {
  transition: opacity 0.7s, transform 2.5s;
}

.img-effect:hover img {
  transform: scale3d(1.2, 1.2, 1);
}

/* Image opacity hover */

.img-hover img {
  transition: opacity 0.2s;
  opacity: 1;
}

.img-hover:hover img {
  opacity: 0.8;
}

/* Image border */

.img-border {
  border: 4px double
}

/* Dropdown */

.dropdown-menu {
  min-width: 180px;
  margin-top: -1px !important;
  border-radius: 20px;
}

.dropdown-menu>li>a {
  display: block;
  padding: 13px 10px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #fff;
  white-space: nowrap;
  border-radius: 0px;
  transition: all .2s ease-in-out;
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
  color: #fff;
  transition: all .2s ease-in-out;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
  background-color: transparent;
  color: #333 !important;
}

/* Pagination */

.pagination>li {
  display: inline-block;
}

.pagination>li>a,
.pagination>li>span {
  margin: 0 1px;
  border: 0;
  padding: 5px 12px;
  color: #fff;
  border-radius: 50%;
  transition: all .2s ease-in-out;
}

.pagination>li>a:hover,
.pagination>li>a:focus {
  color: #fff;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span,
.pagination>li:first-child>a,
.pagination>li:first-child>span {
  border-radius: 50%;
}

/* Alerts */

.alert {
  /* border-radius: 20px 40px; */
  /* padding: 25px;
  font-size: 16px; */
  border: none !important;
  letter-spacing: 0.5px;
  /* font-weight: 700; */
  /* margin: 20px 0px; */
  position: absolute;
  top: 100%;
  left: 0;
  background: none;
  padding: 0 !important;
}

.alert.alert-success {
  color: #fff;
}


/* Breadcrumbs */

.breadcrumb {
  padding: 8px 25px;
  list-style: none;
  margin-bottom: 0px;
  text-align: left;
  font-size: 16px;
  display: inline-block;
  font-weight: 700;
  font-family: Lato;
  background: transparent;
  border-radius: 0px;
}

ul.breadcrumb {
  line-height: 18px !important;
  text-align: center;
}

.breadcrumb a {
  color: #fff;
}

.breadcrumb a:hover,
.breadcrumb a:focus,
.breadcrumb>.active {
  color: #cecece;
}

.breadcrumb>li+li:before {
  padding: 0 10px;
  content: "»";
  color: #fff;
  font-size: 16px;
}

/* Labels */

.label {
  padding: .2em .6em .3em;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.8;
  color: #fff;
  border-radius: 10px;
}

/* Form Labels */

label {
  font-weight: 400;
  margin-top: 10px;
  display: block;
  max-width: 100%;
  font-size: 16px;
  margin-bottom: 5px;
}

/* Progress bar */

.progress-bar {
  height: 10px;
  box-shadow: 6px 6px 0px rgba(103, 102, 102, 0.2);
}

.progress {
  box-shadow: none;
}

/* fade tab transition */

.fade {
  opacity: 0;
  transition: opacity 0.55s linear;
}

/* Page  */

.pages {
  padding: 0px 0px 90px;
}

.image-page {
  margin: 20px 0;
}

/* Pager */

.pager li>a,
.pager li>span {
  display: inline-block;
  padding: 10px 24px;
  font-size: 18px;
  color: #fff;
  font-family: 'Lato', sans-serif;
  border-radius: 20px;
  transition: all .2s ease-in-out;
}

.pager li>a:hover {
  color: #fff;
}

/* Nav-tabs */

.nav-tabs {
  border-bottom: 0 none;
  margin-left: 30px !important;
}

.nav-tabs li a {
  font-weight: 900;
  position: relative;
  padding: 20px 30px;
  color: #fff !important;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.7px;
  z-index: 1;
  text-transform: uppercase;
}

.nav-tabs li a:hover {
  background: transparent;
  border: 1px solid transparent;
}

.nav-tabs li:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: -1px;
  font-size: 17px;
  /* border: 1px solid #cecece; */
  border: 1px solid #282A73;
  /* border-bottom: 0px none; */
  border-radius: 10px 10px 0 0;
}

.nav-tabs li.active a:before {
  background: #f5f1e98f !important;
}

.nav-tabs li.active a,
.nav-tabs li.active a:focus,
.nav-tabs li.active a:hover {
  background: #f5f1e98f !important;
  z-index: 2;
  border: 0px;
  bottom: -1px;
}

.tab-content .tab-pane {
  padding: 50px;
  /* padding-top: 90px; */
  border-radius: 20px;
  line-height: 22px;
}

.tab-content .tab-pane p {
  max-width: 90%;
}

.tab-content>.active {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}

.nav-tabs li button {
  font-weight: 700;
  position: relative;
  background: none;
  padding: 3px 10px;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.7px;
  z-index: 1;
  text-transform: uppercase;
}

.nav-tabs li button:hover,
.nav-tabs li button:focus {
  background: #282A73;
  color: #fff;
  text-decoration: none;
  border-radius: 5px 5px 0 0;
}

.nav-tabs li.active button:before {
  background: #f5f1e98f !important;
}

.nav-tabs li.active button,
.nav-tabs li.active button:focus,
.nav-tabs li.active button:hover {
  background: #282A73;
  z-index: 0;
  color: #fff;
  border-radius: 5px 5px 0 0;
}

.about-tab-section ul li {
  margin-right: 3px;
}

.about-tab-section {
  margin-bottom: 70px;
}

/* Panels */

.panel {
  border: 0;
  box-shadow: none;
  margin: 0;
}

.panel-heading {
  padding: 0;
  border: 0;
}

.panel-title a {
  display: block;
  box-shadow: 5px 5px 0px 2px rgba(181, 180, 180, 0.41);
  font-weight: normal;
  padding: 20px;
  letter-spacing: 0.5px;
  font-size: 18px;
  font-family: Baloo;
  color: #fff !important;
  border: 0;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s ease 0s;
}

.panel-title a.collapsed {
  background: #fff;
}

.panel-title a.collapsed:hover {
  color: #fff;
}

.panel-group .panel-heading+.panel-collapse>.list-group,
.panel-group .panel-heading+.panel-collapse>.panel-body {
  border-top: 0px;
}

.panel-body {
  padding: 20px;
  border: none;
  border-radius: 50px;
  font-size: 14px;
  line-height: 25px;
}

/* White bg */

.bg-white {
  background-color: #fff !important;
}

/* Lists */

ul.no-margin {
  padding: 0;
}

.list-unstyled li {
  line-height: 40px;
}

ul.custom {
  font-weight: 700;
  list-style: none;
}

ul.custom li:before {
  content: "•";
  font-size: 16px;
  font-weight: 400;
  font-family: "Flaticon";
  padding-right: 7px;
}

/* Buttons */

.btn,
.btn .btn-primary {
  display: inline-block;
  background: transparent;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.5px;
  border-radius: 25px;
  padding: 8px 30px;
  text-decoration: none;
  border: 0px;
  font-family: "Lato", serif;
}

.btn:hover,
.btn .btn-primary:hover {
  color: #fff;
}

.btn .fa,
.btn.focus,
.btn:focus {
  color: #fff !important;
}

.margin-btn {
  margin-left: 10px !important;
}

/* Forms */

.form-control {
  transition: all 0.7s ease-out;
  display: block;
  width: 100%;
  border: 2px solid;
  height: 51px;
  font-size: 17px;
  outline: none;
  border-radius: 15px;
  box-shadow: none;
  margin-bottom: 15px;
}

select.form-control {
  border: 4px solid;
  margin-top: 20px;
  font-weight: 700;
  font-size: 20px;
}

.form-horizontal .control-label {
  text-align: left !important;
}

.input-group-btn:last-child>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-btn .btn {
  font-size: 12px;
  padding: 15px 30px;
  margin-top: 0px;
}

.input-group-btn span,
.input-group-addon span,
.btn span {
  color: #fff;
}

.form-control.form-control.input-lg {
  margin-top: 0px;
}

.form-group {
  margin-bottom: 0px;
}

.form-control:focus,
textarea.form-control:focus,
.textarea-field:focus,
.input-field:focus {
  outline: none;
  box-shadow: none;
}

textarea {
  height: 150px;
  resize: none;
}

/* BIG Social Media Icons */

.social-media {
  width: 100%;
  text-align: left;
  margin-top: 20px;
}

.social-media a {
  display: inline-block;
  font-size: 30px;
  margin: 5px;
}

.social-media a i:hover {
  transform: scale(1.2);
}

.social-media a i {
  font-weight: normal;
  transition: all .3s ease-in-out;
  margin: 3px;
}

.social-media i {
  transition: all .3s ease-in-out;
}

/* Small Social Media Icons */

.smaller.social-media a i {
  font-size: 22px;
  margin: 20px;
  transition: .3s;
  margin: 0px;
  border: 0px;
  background: none;
  padding: 0;
  color: #fff;
  width: 30px;
}

.smaller.social-media a i:hover {
  background: none;
}

.smaller.social-media {
  margin-top: 10px;
}

/* Centered columns */

.col-centered {
  float: none;
  margin: 0 auto;
}

/* small icons margin */

.margin-icon {
  margin-right: 10px;
}

/* Blockquote */

blockquote {
  font-size: 22px;
  padding: 30px;
  color: #fff;
  border-radius: 20px;
  line-height: 32px;
  border-left: none;
  text-align: center;
}

blockquote:before {
  content: "\f10d";
  margin-right: 20px;
  font-family: 'FontAwesome';
  font-size: 40px;
}

/* Icon-sizes */

.big-icon i {
  font-size: 220px;
}

/* Nav-pills */

.nav-pills {
  display: inline-block;
}

.nav-pills>li>a {
  transition: all .2s ease-in-out;
  border-radius: 0px;
  font-size: 18px;
  margin: 5px;
  border-radius: 10px;
  text-transform: uppercase;
  padding: 10px 25px;
  letter-spacing: 1.5px;
  font-family: 'Baloo', sans-serif;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus,
.nav-pills>li>a:hover,
.nav-pills>li>a,
.nav-pills .nav>li>a:hover {
  color: #fff;
}

/* Remove padding in container fluid */

/* .container-fluid {
  padding-right: 0px;
  padding-left: 0px;
} */

/* Tables */

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid;
}

/* SVG Ornaments */

#cloud1 {
  margin-top: -90px;
  position: absolute;
}

#cloud2 {
  fill: #ffffff;
  margin-top: -100px;
  position: absolute;
}

#cloud1.colored {
  fill: #66CCFF;
}

#curveDownColor1 {
  position: absolute;
  margin-bottom: 20px;
}

/* Parallax objects */

.parallax-obj1 {
  position: absolute;
  top: auto;
  left: 15px;
  /* z-index: 99; */
}

.parallax-obj2 {
  position: absolute;
  top: auto;
  right: 0;
}

.team-para {
  transform: translateY(-25%);
}

.about-para {
  transform: translateY(-40.4252%);
  left: 10px;
  right: unset;
}

.about-para2 {
  transform: translateY(252.5748%);
  right: 21px;
}

.about-para2 img {
  width: 240px;
}

.about-para img {
  width: 200px;
}

.testimonial-para {
  transform: translateY(-55.0495%);
}

.blog-para {
  transform: translateY(-67.711%);
}

/* Wells */

.well {
  box-shadow: 10px 0px 0px 0px rgba(153, 153, 153, 0.22);
  background: #fff;
  border-radius: 55px 160px;
  padding: 60px;
  border: 0;
}

.well .list-unstyled {
  padding-left: 15px;
  font-size: 16px;
  list-style: none;
  line-height: 30px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
}

/* Paper */

.paper:before,
.paper:after {
  content: "";
  height: 98%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.paper:before {
  background-image: url(../img/linedpaper.png);
  background-repeat: repeat;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  left: -5px;
  top: 4px;
  transform: rotate(-2.5deg);
}

.paper:after {
  background-image: url(../img/linedpaper.png);
  background-repeat: repeat;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  right: -3px;
  top: 1px;
  transform: rotate(1.4deg);
}

.paper {
  z-index: 1;
  position: relative;
  background: #fff;
  padding: 30px;
}

/* Media */

.media-heading small {
  font-weight: 800;
  display: block;
  font-size: 13px;
  margin: 10px 0 20px;
  line-height: 20px;
  letter-spacing: 0.6px
}

/* Margins */

.margin1 {
  margin-top: 50px;
}

/* Jumbotron */

.jumbotron {
  background-image: url(../img/jumbotron.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 310px;
  background-position: center;
}

.jumbo-heading {
  background-image: url(../img/bgdots.png), url(../img/bgdots2.png);
  background-position: center left, center right;
  background-repeat: no-repeat;
  padding: 30px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 60px;
}

.jumbo-heading h1 {
  color: #fff;
  position: relative;
  margin: 10px auto;
}

/* Bubble */

.bubble {
  box-shadow: 10px 0px 0px 0px rgba(153, 153, 153, 0.22);
  border-radius: 35px 120px;
  padding: 50px;
  color: #fff;
}

/* Sidebar */

.sidebar {
  border-radius: 20px;
  padding: 20px;
}

.sidebar .well {
  padding: 10px;
  border: 0px;
  background: transparent;
  border-radius: 0px;
  box-shadow: none;
}

.sidebar-header {
  color: #fff !important;
  border-bottom: 2px solid #cecece;
  padding-bottom: 20px;
  margin-top: 0px;
  border-radius: 20px 40px;
  text-align: center;
  padding: 10px;
}

.sidebar .list-unstyled {
  font-size: 18px;
  line-height: 24px;
}

/* Mailchimp message */

#mce-error-response,
#mce-success-response {
  display: none;
  font-size: 14px;
}

#mc_embed_signup div.mce_inline_error {
  border-radius: 20px;
}

/* divider */

hr {
  border-top: 1px solid #161616;
}

/* small divider */

.hr {
  border-top: 3px solid;
  text-align: center;
  max-width: 50px;
  margin: 0 auto;
  margin-bottom: 20px;
}

/* List group */

.list-group-item {
  position: relative;
  display: block;
  border-radius: 0px;
  padding: 10px 15px;
  margin-bottom: -1px;
  transition: all 0.6s;
}

a.list-group-item,
button.list-group-item {
  color: #fff;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.list-group-item:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

a.list-group-item,
button.list-group-item {
  color: #fff;
}

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
  color: #fff;
  text-decoration: none;
}

/*===========================
Media Queries
============================ */
/************ min width 1900px *************/

@media (min-width: 1900px) {
  /* Slider */

  #layerslider {
    height: 900px !important;
  }
}

/************ min width 1500px *************/

@media (min-width: 1500px) {
  .ls-bg {
    width: 100% !important;
  }
}

/************ max width 1200px *************/

@media (max-width: 1200px) {
  .navbar-nav>li>a {
    padding: 10px 15px;
  }

  .layer-section {
    margin-top: 40px;
  }
}

/************ max width 1300px *************/

@media (max-width: 1300px) {
  /* Slider */

  .header-text {
    padding: 30px !important;
    max-width: 60%;
    border-radius: 50px 25px;
    left: 0% !important;
    right: 25% !important;
    top: 20% !important;

  }

  #layerslider {
    height: 600px !important;
  }
}

/************ max width 991px *************/

@media (max-width: 991px) {

  /* backgrounds on small screens */
  #call-to-action1,
  #call-to-action2 {
    background-attachment: scroll !important;
  }

  /* Slider */

  #layerslider {
    height: 470px !important;
    padding-top: 45px;
  }

  .header-text h1 {
    font-size: 44px;
    line-height: 44px;
    margin-top: 0px;
  }

  .header-text {
    max-width: 620px;
    top: 29% !important;
    left: 15% !important;
    right: 15% !important;
  }

  .ls-bottom-nav-wrapper {
    margin-top: -20px !important;
  }

  /* Headers */

  h1 {
    font-size: 46px !important;
    line-height: 52px;
  }

  h2 {
    font-size: 44px !important;
  }

  h3 {
    font-size: 38px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  /* Responsive margin*/

  .res-margin,
  .margin1 {
    margin-top: 30px;
  }

  /*navbar */

  .nav>li {
    text-align: center;
    font-size: 20px;
  }

  /* navbar collapse at 991px */

  .navbar-custom li:after {
    display: none;
  }

  .navbar-header {
    float: none;
    margin-top: -2px;
  }

  .navbar-toggle {
    display: block;
    line-height: 30px;
  }

  .navbar-toggle i {
    line-height: 30px;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-collapse {
    max-height: 400px !important;
  }

  .navbar-custom ul.nav li a {
    line-height: normal;
  }

  .navbar-custom .nav li::before {
    display: none;
  }


  .navbar-collapse.collapse.in {
    display: block !important;
    padding-bottom: 40px;
    max-height: 400px;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }

  .navbar-collapse::-webkit-scrollbar {
    width: 20px;
  }

  .navbar-collapse::-webkit-scrollbar-track {
    background-color: lightgray;
  }

  .navbar-collapse::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }

  .navbar-nav {
    margin: 0;
    float: none !important;
  }

  .navbar-right {
    line-height: 0px;
  }

  .navbar-nav li {
    float: none;
    height: auto;
  }

  .navbar-nav li>.dropdown-menu {
    border: 0;
    float: none;
    width: auto;
    position: relative;
    box-shadow: none;
    background: inherit;
    text-align: center;
  }

  .navbar-brand img {
    position: relative;
  }

  .navbar-custom .nav a:hover,
  .navbar-custom .nav>.active>a {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  /* Space between sections */

  .section-heading {
    margin-bottom: 30px;
  }

  /* miscelaneous */

  .sidebar {
    border: 0px;
  }

  .back-to-top {
    display: none;
  }

  .img-paragraph {
    margin: 0px 0px 20px 0px;
  }

  .no-padding {
    padding: 20px;
  }

  .blog-post {
    padding: 20px 0;
  }

  .menu-body {
    padding: 0px;
  }

  .nobg-small {
    background-image: none !important;
  }

  .nav-tabs li a {
    padding: 15px 10px;
    font-size: 14px;
  }

  .nav-tabs li a:before {
    bottom: 6px;
  }

  .pricingTable {
    margin-bottom: 80px;
  }




}


@media (min-width:577px) and (max-width:767px) {
  .header-text {
    padding: 15px !important;
    min-width: 99%;
    margin: 0 auto !important;
    left: 0% !important;
    top: 25% !important;
  }
}

@media (max-width:576px) {
  .header-text {
    padding: 15px !important;
    min-width: 99%;
    margin: 0 auto !important;
    left: 0% !important;
    top: 20% !important;
  }
}

/************ max width 767px *************/

@media (max-width: 767px) {
  /* Slider */

  #layerslider {
    height: 350px !important;
  }



  .header-text h1 {
    font-size: 30px;
    line-height: 34px;
    margin: 0px;
  }

  .ls-nav-prev,
  .ls-nav-next {
    display: none !important;
  }

  .ls-bottom-nav-wrapper {
    margin-top: 0px !important;
  }

  /* jumbotron */

  .jumbotron {
    height: 200px;
  }

  /* Miscelaneous */

  .navbar-nav .open .dropdown-menu>li>a,
  .navbar-right li {
    line-height: normal;
  }

  .navbar-custom ul.nav li a {
    line-height: normal;
  }

  .tab-content {
    margin-top: 0px;
  }

  .breadcrumb>li+li:before {
    padding: 0 4px;
  }

  h1 {
    font-size: 2.998em;
  }

  .nav-pills>li>a {
    font-size: 18px;
    margin: 5px;
    line-height: 22px;
    padding: 10px;
  }

  section {
    padding: 50px 0px;
  }

  #call-to-action1,
  #call-to-action2 {
    background-attachment: scroll;
  }

  /* #main section {
    padding: 30px 0px;
  } */

  .nav-tabs li {
    width: auto;
    margin-bottom: 5px;
    float: none;
  }

  .nav-tabs li a:before {
    bottom: 0;
    transform: none;
    border-bottom: 1px solid #d3d3d3;
  }

  .nav-tabs {
    margin-left: 0px !important;
    overflow-x: auto;
    overflow-y: hidden;
    display: -webkit-box;
    display: -moz-box;
  }

}

/************ max width 640px *************/

@media (max-width: 640px) {
  /* navbar */

  .navbar-collapse {
    max-height: 230px !important;
  }

  /* Slider */

  #layerslider {
    height: 320px !important;
  }
}

/************ max width 480px *************/
@media (max-width:576px) {
  .container>.navbar-header {
    margin: 3px 0;
  }

  .navbar-toggle {
    min-width: unset;
    margin-right: 0;
  }


}

/************ max width 480px *************/

@media (max-width: 480px) {
  .well {
    border-radius: 15px 90px;
    padding: 20px;
  }

  .main-slide img {
    height: 250px;
    object-fit: cover;
  }

  /* navbar */

  .navbar-brand img {
    max-width: 90px;
  }

  /* jumbotron */

  .jumbotron {
    height: 170px;
  }

  /* slider */

  #layerslider {
    height: 230px !important;
  }

  .header-text h1 {
    font-size: 24px !important;
    line-height: 24px;
    margin: 0px;
  }

  .ls-bottom-nav-wrapper {
    display: none;
  }

  /* Headers */

  h1 {
    font-size: 36px !important;
    line-height: 40px !important;
  }

  h2 {
    font-size: 30px !important;
  }

  h3 {
    font-size: 26px;
    line-height: 34px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
    text-transform: uppercase;
  }

  h6 {
    font-size: 18px;
  }

  /* Miscellaneous */

  .panel-title a {
    text-align: center;
    padding: 15px;
  }

  .panel-body {
    padding: 20px;
    margin-left: 0px;
  }

  .section-heading h2 {
    border: 0;
    line-height: 34px;
    letter-spacing: 0px;
    font-size: 26px;
  }

  .section-heading {
    margin-bottom: 2rem;
  }

  .jumbo-heading {
    margin-bottom: 30px;
  }

  .menu-item-price {
    margin-top: 10px;
  }

  .block {
    padding: 10px;
  }

  h1 span {
    font-size: 38px;
  }

  .testimonial-review img {
    margin-left: 0px;
  }

  .date-category i {
    display: none;
  }

  .date-category {
    position: absolute;
    color: #fff;
    min-width: 0px;
    padding: 20px;
  }

  .date-category p {
    margin: 0px;
    font-size: 14px;
    line-height: 18px;
  }

  .date-category {
    padding: 10px;
    width: 100px;
  }

  .navbar.navbar-custom.navbar-fixed-top {
    margin-bottom: 30px;
  }

  .breadcrumb {
    font-size: 13px;
  }

  .post-info p {
    line-height: 13px;
  }

  .comments-block {
    padding: 0px;
  }

  section {
    padding: 40px 0px;
  }

  .input-group-btn:last-child>.btn {
    border-radius: 20px;
    margin-top: 10px;
  }

  .input-group .form-control:first-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .input-group .form-control,
  .input-group-btn {
    display: inline-table;
  }

  .nav-pills>li.active:after,
  .nav-pills>li.active:before {
    top: -45%;
  }

  .nav-pills>li {
    float: none;
  }

  .owl-carousel {
    padding: 0px 0px;
  }

  .comment {
    padding: 10px;
    text-align: center;
  }

  .comments-block .media-object {
    float: none;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }

  .comment.media-body {
    margin-right: 0px;
  }

  .tab-pane h3 {
    margin-top: 10px;
  }

  .btn {
    font-size: 13px;
    margin: 10px 0px;
    padding: 15px 20px;
  }

  .spinner {
    left: 42%;
  }

  .glyph {
    width: 100%;
  }

  .sidebar {
    padding: 0px;
  }

  .tab-content .tab-pane {
    padding: 20px;
  }

  .slide img {
    transform: rotate(0deg);
  }

  .heading::before {
    top: -14px !important;
  }
}

/************ max width 340px *************/

@media (max-width: 340px) {
  /* slider */

  #layerslider {
    height: 190px !important;
  }

  /* Miscellaneous */

  .label,
  .table {
    font-size: 14px;
  }

  .table table,
  .table thead,
  .table th,
  .table td,
  .table tr {
    display: block;
  }
}

.h-100 {
  height: 100% !important;
}

.main-slide {
  position: relative;
}

.slide-item {
  position: absolute;
}

.main-slide img {
  width: 100% !important;
}

.text-normal {
  font-weight: 400 !important;
}

.program-li-before::before {
  color: #39b876 !important
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-2 {
  margin-bottom: 10px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.display-block {
  display: block !important
}

.min-width-80 {
  min-width: 80% !important;
}

.text-dark {
  color: #666 !important;
}

.text-black {
  color: #000 !important;
}

.pa-10 {
  padding: 10px !important;
}

.pa-0 {
  padding: 0px !important;
}

.btn-outline {
  border: 1px solid white;
}

.about-hr:after {
  content: "\f100" !important;
}

.programs-hr:after {
  content: "\f120" !important;
}

.contact-hr:after {
  content: "\f12a" !important;
}

.swiper-pagination {
  /* width: 16px !important;
  height: 16px !important;
  opacity: 1 !important; */
  display: none;
}

.swiper-pagination-horizontal {
  bottom: 30px !important;
}

.remove-bg {
  background-color: transparent !important;
}

.gallery-hr:after {
  content: "\f11a" !important;
}

.h5-text {
  font-size: 24px;
  color: #333;
  letter-spacing: 0.4px;
  margin-top: 20px;
}

.black-bg {
  background: rgba(0, 0, 0, 0.6) !important;
}

.white-bg {
  background: rgba(255, 255, 255, 1) !important;
}


.banner-span {
  font-size: 16px;
  line-height: 30px;
  color: #fff
}

.logo-background {
  background: linear-gradient(to bottom, transparent 0%, #ffffff 100%);
}

.mt-1r {
  margin-top: 1rem !important;
}


@media (min-width: 1900px) {
  .mt-35r {
    margin-top: 3.5rem !important;

  }
}

/************ min width 1500px *************/

@media (min-width: 768px) {
  .mt-35r {
    margin-top: 1.5rem !important;

  }

}

@media (min-width: 1900px) {
  .mt-35r {
    margin-top: 3.5rem !important;

  }
}

/************ max width 1200px *************/

@media (max-width: 1200px) {
  .mt-35r {
    margin-top: 3.5rem !important;

  }

}

/************ max width 1300px *************/

@media (max-width: 1300px) {
  .mt-35r {
    margin-top: 3.5rem !important;

  }
}


/************ max width 991px *************/

@media (max-width: 991px) {
  .mt-35r {
    margin-top: 0 !important;
  }

}

@media (max-width: 640px) {
  .mt-35r {
    margin-top: 0 !important;
  }
}

@media (max-width: 480px) {
  .mt-35r {
    margin-top: 0 !important;
  }
}



.mb-2r {
  margin-bottom: 2rem !important;
}

.mr-halfr {
  margin-bottom: .1rem !important;
}


.border-radius {
  border-radius: 25px 10px !important;
}

.jumbo-heading .hr {
  border-top: 1px dotted;
  text-align: center;
  max-width: 150px;
  margin-top: 50px;
  margin: 0 auto;
}

.jumbo-heading .hr:after {
  content: "\f101";
  font-family: Flaticon;
  display: inline-block;
  position: relative;
  top: -25px;
  padding: 0 10px;
  font-size: 38px;
}

.contact-para {
  right: 2rem !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.px-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.nav a:hover {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.custom-padding {
  padding: 10px 25px !important
}

.left-0 {
  left: 0 !important
}

.navbar-dropdown-line-height {
  line-height: normal !important
}

.h-auto {
  height: auto !important;
}

.mw-fit {
  min-width: fit-content !important;
}

.ma-0 {
  margin: 0px !important;
}

.w-100 {
  width: 100% !important;
}

.text-start {
  text-align: left !important;
}


.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #0d6efd40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle)
}

.accordion-button {
  align-items: center;
  background-color: #ffe9d8;
  border: 0;
  border-radius: 0;
  color: var(--bs-accordion-btn-color);
  display: flex;
  font-size: 18px;
  overflow-anchor: none;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  position: relative;
  text-align: left;
  transition: var(--bs-accordion-transition);
  width: 100%;
  margin-bottom: 15px;
  color: #000;
}

.accordion-button:not(.collapsed) {
  background-color: #ffe9d8;
  box-shadow: inset 0 calc(var(--bs-accordion-border-width)*-1) 0 var(--bs-accordion-border-color);
  color: #000;
}

.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform)
}

.accordion-button:after {
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  content: "";
  flex-shrink: 0;
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  transition: var(--bs-accordion-btn-icon-transition);
  width: var(--bs-accordion-btn-icon-width)
}

@media (prefers-reduced-motion:reduce) {
  .accordion-button:after {
    transition: none
  }
}

.accordion-button:hover {
  z-index: 2
}

.accordion-button:focus {
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: none;
  outline: 0;
  z-index: 3
}

.accordion-header {
  margin-bottom: 0
}


.accordion-body {
  padding: 15px 15px 30px;
  font-size: 16px;
}



.mt-30 {
  margin-top: 30px;
}

.facilities .container .row .col-md-4 {
  margin-top: 30px;
}

.serviceBox-1 {
  text-align: center;
  padding: 30px 20px;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  color: #fff;
  height: 100%;
}

.serviceBox-1 img {
  margin: auto;
}

.serviceBox-1 h3 {
  font-size: 20px;
  margin-top: 20px;
  color: #fff;
  margin-bottom: 15px;
  ;
}

.serviceBox-1 p {
  color: #fff;
}

.serviceBox-1:hover img {
  transform: rotate(-10deg);
  transition: all 0.3s ease-in-out;

}

@media (min-width:767px) {
  .facilities .container .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .facilities .container .row .col-md-4 {
    flex: 0 0 auto;
    width: 50%;
    margin-top: 30px;
  }


  .rowflex {
    display: flex !important;
    flex: 0 0 auto;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
  }

}

@media (min-width:992px) and (max-width:1100px) {
  .navbar-right .dropdown-menu {
    left: unset !important;
    width: 100%;
  }
}


@media (min-width:992px) {
  .facilities .container .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }


  .facilities .container .row .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333%;
    margin-top: 30px;
  }

}

.tagline {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 24px;
  padding: 20px;
  background-color: #304566;
  border-radius: 15px;
  color: #fff;
}

.tagline a {
  color: #EE4323;
  word-break: break-all;
}

.tagline a:hover {
  color: #FF7F67;
}

.contactBox section {
  padding: 20px 0px !important;
}

.pl20 {
  padding-left: 20px;
}

@media (min-width:992px) {
  .tagline {
    padding-right: 100px;
  }

  /* .main-slide {
    height: 480px !important;
  } */

  .header-text span {
    font-size: 34px;
  }
}

@media (min-width:1200px) {
  /* .main-slide {
    height: 600px !important;
  } */

  .header-text span {
    font-size: 36px;
  }
}

@media (min-width:1280px) {
  .tagline {
    padding-right: 100px;
  }
}

@media (min-width:1400px) {
  .tagline {
    padding-right: 20px;
  }

  /* .main-slide {
    height: 800px !important;
  } */

  .header-text span {
    font-size: 60px;
  }
}

@media (min-width:1920px) {
  .main-slide {
    height: 100% !important;
  }
}


.footer-location {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-radius: 15px;
  background-color: #FFE9D8;
  border: 1px solid #FFE9D8;
  text-align: center;
}

.footer-location ul {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Salsa", serif;
  gap: 15px;
  padding: 0;
}

.footer-location ul li {
  list-style: none;
}

.footer-location .hr {
  border-top: 1px dotted;
  margin: 0 auto;
  text-align: center;
  color: #304566;
  width: 100%;
  max-width: unset !important;
  margin-bottom: 30px;
  margin-top: 20px;
}

.footer-section {
  background-color: #F4F1EA;
  padding-top: 70px;
  position: relative;
  z-index: 1;
}

.footer-section::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  background-image: url("../images/cloud.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 670px;
}

.footer-section::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  background-image: url("../images/testimonial-after.webp");
  background-repeat: no-repeat;
  height: 100%;
  z-index: -1;
}

.newsletter {
  padding: 20px;
  text-align: center;
  max-width: 750px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.newsletter-title {
  font-size: 16px;
  color: #161616;
  text-align: left;
  margin-bottom: 10px;
  margin-left: 20px;
}

.newsletter-form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
}

.newsletter-input {
  padding: 10px 20px;
  border: 1px solid #161616;
  border-radius: 30px;
  flex-grow: 1;
  outline: none;
  font-size: 16px;
  position: relative;
  font-weight: 600;
  height: 65px;
  background: none;
  font-family: 'Lato', serif;
}

.newsletter-input::placeholder {
  color: #161616;
}

.newsletter-button {
  padding: 10px 25px;
  background-color: #EE4323;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  left: 81%;
}

.newsletter-button:hover {
  background-color: #1C723D;
}

.footer-para1 {
  transform: translateY(-94.4252%);
  left: 0;
  right: unset !important;
}

.footer-para2 {
  transform: translateY(-62.4252%);
  left: unset !important;
  right: 0;
  z-index: -2;
}

.footer-para1 img {
  width: 330px;
}

.footer-para2 img {
  width: 520px;
}

.team-content-section {
  margin-bottom: -98px;
}

.team-container {
  padding: 30px;
  background-color: #ffff;
  border-radius: 15px;
}

.team-content {
  background-color: #FFFFFF;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 54px 85px rgb(22 34 51 / 15%);
  margin-top: 95px;
}

.team-para-1 {
  transform: translateY(-31.4252%);
  z-index: 1;
  right: -85px;
}

.team-para-1 img {
  width: 400px;
}

section#team {
  padding-bottom: 140px;
  background-color: #F9F7F3;
}

.team-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team-para2 {
  left: 0;
  right: unset;
  transform: translateY(207%);
}

.team-para3 {
  left: 192px;
  right: unset;
  transform: translateY(389%);
  z-index: 0;
}

.team-para4 {
  right: 89px;
  left: unset;
  transform: translateY(-375%);
  z-index: 0;
}

.team-para5 {
  right: 0;
  transform: translateY(-49%);
  left: unset;
  z-index: 0;
}

.service-para1 {
  transform: translateY(71%);
  left: unset;
  right: 73px;
}

.service-para1 img {
  width: 130px;
}

.service-para2 {
  transform: translateY(-160%);
}

.service-para2 img {
  width: 230px
}

.layer-section {
  margin-top: 40px;
  margin-bottom: 40px;
}

.layer-section .row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.phone-img {
  display: none;
}

.page-scroll .btn {
  text-shadow: none !important;
}

.services .container-fluid,
#blog .container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (max-width:1400px) {
  .team-para5 {
    display: none;
  }
}

.service-p {
  display: none;
}

@media screen and (min-width:1560px) {
  .service-p {
    display: block;
  }
}

@media (max-width:1600px) {
  .footer-para1 {
    display: none;
  }
}

@media (max-width:991px) {
  .phone-img {
    display: block;
  }

  .team-content {
    margin-top: 0 !important;
  }

  .testimonial-slide-col {
    padding-top: 50px !important;
  }

  .footer-section::before {
    display: none;
  }

  footer {
    background-color: #d9d9d9;
    margin-top: 30px;
  }

  .here-img {
    display: none;
  }

  .section-heading h2 .heading:before {
    left: 42%;
  }
}

@media (max-width:767px) {
  .layer-section img {
    width: 100%;
  }

  .newsletter-button {
    left: unset;
    position: relative;
  }

  .newsletter-input {
    height: 50px;
  }

  .newsletter-form {
    flex-wrap: wrap;
  }

  .team-container {
    padding: 30px 0;
  }
}

.inner-service-para {
  transform: translateY(-70%);
}

.swiper-button-prev,
.swiper-button-next {
  color: #fff !important;
}

.announcement-popup .modal-header {
  background-color: #EE4323;
}

.announcement-popup .modal-header h3,
.announcement-popup .modal-header p {
  color: #fff;
}

.services h4{
  line-height: 44px;
}

.partners-row{
  background-color: #F4F1EA; 
  border-radius: 16px;
  padding-top: 20px ;
  padding-bottom: 20px;
  color: #000;
}

.partners-container{
  margin-top: 70px;
  margin-bottom: 50px;
}

.partners-logo{
  height: 118px;
  margin-bottom: 25px;
  mix-blend-mode: darken
}

.partners-row .card-text {
  color: #000;
  padding: 0 15px;
}

@media (min-width: 992px) {
  .partners-row .col-md-4:not(:last-child) .card-text {
    border-right: 1px solid #C0B399;
  }

  .partners-row .col-md-4 {
    margin-bottom: 30px;
  }
}