.gallery-sidebar-container {
    background-color: #EE4323;
    color: #fff;
    padding: 30px 15px;
    height: auto;
    position: sticky;
    top: 90px;
    display: flex;
    flex-direction: column;
    gap: 35px;
    border-radius: 12px;
}

.gallery-sidebar-container h4 {
    margin-top: 0;
}

.gallery-sidebar-container h4,
.gallery-sidebar-container h5 {
    color: #fff;
}


.sidebar-year {
    display: flex;
}

.sidebar-year h4 {
    padding: 5px 7px;
    border: 1px solid #ffffff63;
    font-size: 13px;
    font-weight: bold;
    border-radius: 4px;
    margin-bottom: 0;
    font-family: 'Lato', serif;
}
.sidebar-item {
    border-bottom: 1px solid #ffffff63;
    padding-bottom: 10px;
    margin-top: 15px;
}
.gallery-sidebar-container .btn-sm {
    display: block;
    text-align: left;
    padding: 5px 9px;
    background-color: #fff;
    color: #304566;
}
.gallery-sidebar-container h5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.gallery-sidebar-container .dropdown-toggle {
    cursor: pointer;
    position: relative;
    margin: 15px 0;
    font-size: 16px;
    font-family: 'Lato', serif;
}
.gallery-sidebar-container .dropdown-content.show {
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-timing-function: opacity cubic-bezier(0, 1, .4, 1);
    animation-timing-function: opacity cubic-bezier(0, 1, .4, 1)
}
.gallery-images-container {
    background-color: #f5f1e9;
    padding: 15px;
    border-radius: 12px;
}

.gallery-images-container .col-md-4 {
    padding-bottom: 10px;
    padding-right: 5px;
    padding-left: 5px;
}

.gallery-images-container img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 12px;
}

.gallery-page-container .row {
    margin-left: 0;
    margin-right: 0;
}

.gallery-page-container .col-md-3 {
    width: 23%;
    margin-right: 2%;
}

.pagination {
    margin-top: 20px;
}

.pagination .page-item .page-link {
    background-color: #34367a;
    border-color: #34367a;
    color: #fff;
}

.pagination .page-item.active .page-link {
    background-color: #fff;
    color: #34367a;
    border-color: #34367a;
}
.gallery-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}
.gallery-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 90%;
}

.gallery-modal h2 {
    font-size: 14px !important;
}


.gallery-modal button {
    margin: 5px;
    background-color: #34367a;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

.gallery-modal h2 {
    margin-bottom: 20px;
}

.gallery-modal select {
    margin: 10px 0;
    padding: 5px;
    width: 100%;
}

.top-bar-tab {
    padding: 10px;
    cursor: pointer;
}

.top-bar-tab.active {
    background-color: #34367a;
    color: #fff;
}

.top-bar {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    display: none;
}

.full-siderbar {
    display: block;
}

.mobile-sidebar {
    display: none;
}
@keyframes growIn {
    0% {
        transform: scale(.9);
        opacity: 0
    }

    100% {
        transform: scale(1);
        opacity: 1
    }
}
@media (min-width:992px) {
    .gallery-dropdown-content {
        display: none !important;
    }
}

@media (max-width:991px) {

    .gallery-page-section .jumbo-heading {
        margin-bottom: 0 !important;
    }

    .gallery-sidebar-container {
        position: relative;
        top: 0;
        border-radius: 0;
        display: flex !important;
        flex-direction: row;
        overflow-y: hidden;
        overflow-x: visible;
        border-top: 1px solid #ffffff63;
        padding: 5px 15px;
    }

    .gallery-sidebar-container .col-md-3 {
        width: 100%;
        margin-right: 0%;
        margin-bottom: 20px;

    }

    .e-month {
        display: flex;
        gap: 20px;
    }

    .gallery-sidebar-container .dropdown-toggle {
        margin: 0;
    }

    .e-month .caret {
        display: none;
    }

    .sidebar-item {
        border-bottom: none;
    }

    .full-siderbar {
        display: none !important;
    }

    .mobile-sidebar {
        display: block;
    }

    .gallery-images-container .col-md-4 {
        padding-bottom: 15px;
    }

    .gallery-images-container .col-md-4:last-child {
        padding-bottom: 0;
    }

    .sidebar-year h4 {
        border: none;
        padding: 5px 0;
        color: #ffffff87;
    }

    .gallery-dropdown-content.show {
        position: fixed;
        padding: 10px;
        background-color: #34367a;
        display: flex !important;
        flex-direction: column;
        z-index: 9999;
    }

    .gallery-dropdown-content .btn-sm {
        border: 0;
        border-bottom: 1px solid #fff;
        margin: 0;
        border-radius: 0;
        text-align: left !important;
    }

    .gallery-dropdown-content .btn-sm:last-child {
        border: none !important;
    }

    .sidebar-item {
        margin-top: 0;
    }

    .sidebar-year {
        margin-bottom: 5px;
    }
}
@media screen and (max-width:480px) {
    .dropdown-content-101 {
        right: 10px;
    }
    .dropdown-content-100,
    .dropdown-content-4,
    .dropdown-content-5 {
        right: 10px;
    }
}