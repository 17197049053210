.custom-navbar {
    background-color: #f8f9fa;
    padding: 1em;
}
.custom-navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.custom-navbar-brand {
    font-size: 1.5em;
    font-weight: bold;
}
.custom-navbar-nav {
    list-style: none;
    display: flex;
    gap: 1em;
    margin: 0;
    padding: 0;
}
.custom-nav-item {
    position: relative;
}
.custom-nav-item a :hover{
    color: #34367a;
}
.custom-dropdown-item:nth-child(1){
    border-bottom: 1px solid #EEEEEE;
}
.custom-nav-link {
    text-decoration: none;
    color: #000;
}
.custom-dropdown,
.custom-dropdown-submenu {
    display: none;
    position: absolute;
    top: 0 !important;
    left: 100% !important;
    background-color: #fff;
    z-index: 1;
    right: unset !important;
}
.custom-dropdown-submenu {
    top: 0;
    left: 100%;
    padding-left: 0;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-height: 180px;
    overflow-y: auto;
}
.custom-dropdown-submenu li {
    list-style: none;
}
.custom-dropdown-submenu li a {
    background-color: #F8F8F8 !important;
    padding: 10px 28px;
    border: 1px solid #EEEEEE;
}
.custom-dropdown-submenu li a:hover {
    background-color: #EE4323 !important;
    color: #fff !important;
}
.custom-dropdown-item {
    position: relative;
}
.custom-dropdown-item a {
    display: block;
    padding: 0.5em 1em;
    text-decoration: none;
    color: #000;
    white-space: nowrap;
}
.custom-dropdown-item-a{
    display: flex !important;
    justify-content: space-between !important
}
.custom-nav-item:hover>.custom-dropdown,
.custom-dropdown-item:hover>.custom-dropdown-submenu {
    display: block;
}
@media (min-width:992px) and (max-width:1100px) {
    .dropdown-menu.custom-dropdown-submenu{
      left: 75% !important;
    }
  }
@media (max-width:991px){
    .custom-dropdown-submenu{
        position: relative !important;
        left: 0 !important;
    }
}